<template>
  <div v-if="isLoading" :class="$style.loading">
    <div :class="$style.spinner"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" module>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-loader;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($light-gold, 0.05);
  .spinner {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.25rem solid $light-gold;
    border-radius: 50%;
    border-top-color: $bright-gold;
    animation: spin 1s ease-in-out infinite;
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
</style>